import { useMutation } from '@tanstack/react-query';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiMutationFunction } from 'common/api/fetch-types';
import { MessageResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';

import { CreateFeedbackInput } from './model';
import { feedbackKey } from './query';

const ENDPOINT = 'feedbacks';

export const useFillFeedback: ApiMutationFunction<
  CreateFeedbackInput,
  MessageResult
> = function (options) {
  return useMutation(
    async (input) => {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/${input.feedbackId}`,
        method: 'PUT',
        body: input.body,
      });
    },
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries([feedbackKey.pendingKey]);
        queryClient.invalidateQueries(
          feedbackKey.detail({ id: variables.feedbackId }),
        );
      },
      ...options,
    },
  );
};

export const useSkipAllPendingFeedbacks: ApiMutationFunction<
  void,
  MessageResult
> = (options) => {
  return useMutation(
    async () => {
      return await MutationFetchFunction({
        url: `${ENDPOINT}/skip-all-pending`,
        method: 'PUT',
      });
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries([feedbackKey.pendingKey]);
      },
      ...options,
    },
  );
};
