import { ActionIcon } from '@mantine/core';
import { Star } from '@phosphor-icons/react';
import colors from 'common/styles/colors';
import Text from 'components/elements/text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

interface RatingComponentProps {
  rating: number | undefined;
  setRating?(rating: number): void;
}

export default function RatingComponent(props: RatingComponentProps) {
  const { rating, setRating } = props;
  const [isHover, setIsHover] = React.useState<number | undefined>(undefined);
  return (
    <div
      className={structuralStyles.flexbox({ gap: 4 })}
      onMouseLeave={() => setIsHover(undefined)}
      onTouchCancel={() => setIsHover(undefined)}
      onTouchEnd={() => setIsHover(undefined)}
      onBlur={() => {}}
    >
      {Array.from({ length: 5 }, (_, i) => {
        let color: string = '#D9D9D9';
        const isRated = rating != null && i + 1 <= rating;
        if (isHover != null) {
          if (i <= isHover) {
            color = colors.mainYellow;
          }
        } else if (isRated) {
          color = colors.mainYellow;
        }
        return (
          <ActionIcon
            key={i}
            variant="transparent"
            onClick={
              setRating
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setRating(i + 1);
                  }
                : undefined
            }
            onMouseEnter={() => {
              setIsHover(i);
            }}
            onTouchStart={() => {
              setIsHover(i);
            }}
            size={40}
          >
            <Star size={40} weight="fill" color={color} />
          </ActionIcon>
        );
      })}
    </div>
  );
}

export function RatingComponentWithLabel(props: RatingComponentProps) {
  const { t } = useTranslation();
  return (
    <div className={structuralStyles.flexbox({ direction: 'column', gap: 8 })}>
      <RatingComponent {...props} />
      {props.rating != null && (
        <Text textVariant="body1Semibold">
          {t(`sim:rating.value_${props.rating}`)}
        </Text>
      )}
    </div>
  );
}

interface RatingShortProps {
  rating: number;
}

export function RatingShort(props: RatingShortProps) {
  return (
    <div className={structuralStyles.flexbox({ gap: 4 })}>
      <Text>{Math.max(Math.min(5, props.rating), 0)}</Text>
      <Star weight="fill" color={colors.mainYellow} />
    </div>
  );
}
