import { useQuery } from '@tanstack/react-query';
import { IdInput, StaleTimes } from 'api-hooks/common';
import { QueryTransformer, QueryFetchFunction } from 'common/api/fetch';
import { ApiQueryFunction } from 'common/api/fetch-types';
import { ApiResult } from 'common/api/model';

import { FeedbackModel, FeedbackOptionsModel } from './model';

const ENDPOINT = 'feedbacks';

export const feedbackKey = {
  pendingKey: 'getPendingFeedbacks',
  optionsKey: 'getFeedbackOptions',
  detailKey: 'getFeedback',
  detail(input: IdInput) {
    return [feedbackKey.detailKey, input.id];
  },
};

export const useGetLatestPendingFeedback: ApiQueryFunction<
  never,
  ApiResult<FeedbackModel | null>
> = function (options) {
  return QueryTransformer(
    useQuery(
      [feedbackKey.pendingKey],
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/latest-pending`,
        }),
      {
        staleTime: StaleTimes.Medium,
        ...options,
      },
    ),
    FeedbackModel,
  );
};

export const useGetFeedback: ApiQueryFunction<
  IdInput,
  ApiResult<FeedbackModel>
> = function (input, options) {
  return QueryTransformer(
    useQuery(
      feedbackKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/${input.id}`,
        }),
      {
        staleTime: StaleTimes.Medium,
        ...options,
      },
    ),
    FeedbackModel,
  );
};

export const useGetFeedbackOptions: ApiQueryFunction<
  never,
  ApiResult<FeedbackOptionsModel>
> = function (options) {
  return QueryTransformer(
    useQuery(
      [feedbackKey.optionsKey],
      () =>
        QueryFetchFunction({
          url: `${ENDPOINT}/options`,
        }),
      {
        staleTime: Infinity,
        ...options,
      },
    ),
    FeedbackOptionsModel,
  );
};
