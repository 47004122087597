import { ActionIcon } from '@mantine/core';
import { useSkipAllPendingFeedbacks } from 'api-hooks/feedback/mutation';
import {
  feedbackKey,
  useGetLatestPendingFeedback,
} from 'api-hooks/feedback/query';
import { SimModel, SimTransferRequestInput } from 'api-hooks/sim/model';
import {
  useAcceptTransferRequest,
  useRejectTransferRequest,
} from 'api-hooks/sim/mutation';
import classNames from 'classnames';
import { MessageResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';
import { CancelIcon, PremiumPlanIcon } from 'common/assets';
import FeedbackBackground from 'common/assets/svg/feedback-bg';
import { AssetPaths } from 'common/constants/assets';
import { ProductItemGradeEnum, SimStatusEnum } from 'common/constants/enum';
import notification from 'common/helpers/notification';
import { NavigationRoutes } from 'common/routes';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import Tooltip from 'components/common/tooltip';
import Button, { PromiseButton } from 'components/elements/button';
import Text from 'components/elements/text';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import RatingComponent from 'modules/feedback/components/rating';
import { highlightTargetName } from 'modules/main/components/installation-highlighter';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import EsimPlanComponent from './item';
import { EsimCallToActionStyles } from './styles.css';

interface Props {
  email: string;
  simId: string;
  simTransferRequestId: string;
}

export default function TransferOwnershipRequestConfirmation(props: Props) {
  const { t } = useTranslation();
  const { mutateAsync: acceptTransfer, isLoading: isAccepting } =
    useAcceptTransferRequest();
  const { mutateAsync: rejectTransfer, isLoading: isRejecting } =
    useRejectTransferRequest();
  const [resolved, setResolved] = React.useState(false);
  const { replace } = useKurosimNavigation();
  const onAction = React.useCallback(
    async (
      mutate: (props: SimTransferRequestInput) => Promise<MessageResult>,
    ) => {
      try {
        const res = await mutate({
          simId: props.simId,
          simTransferRequestId: props.simTransferRequestId,
        });
        setResolved(true);
        if (res.message) {
          notification.success({ message: res.message });
        }
      } catch (e) {
        console.error(e);
        notification.error({ message: e.message });
      }
    },
    [props.simId, props.simTransferRequestId],
  );

  if (resolved) {
    return null;
  }
  return (
    <div
      className={classNames(
        structuralStyles.border(),
        structuralStyles.fill({ width: true }),
        structuralStyles.padding({ all: 16 }),
        structuralStyles.flexbox({
          direction: 'column',
          gap: 16,
        }),
      )}
    >
      <div
        className={classNames(
          structuralStyles.flexbox({ justify: 'between' }),
          structuralStyles.fill({ width: true }),
        )}
      >
        <Tooltip label={t('sim:transfer_ownership_request_tooltip')} />
        <Text textVariant="body1Semibold">
          {t('sim:transfer_ownership_request')}
        </Text>
        <div />
      </div>
      <Text>
        <Trans
          i18nKey="sim:transfer_ownership_request_description"
          components={[<Text span textVariant="body1Semibold" key="0" />]}
          values={{
            extra: props.email,
          }}
        />
      </Text>
      <div
        className={classNames(
          structuralStyles.flexbox({ gap: 16 }),
          structuralStyles.fill({ width: true }),
        )}
      >
        <PromiseButton
          variant={{
            variant: 'secondary',
          }}
          onClick={() => onAction(rejectTransfer)}
          fullWidth
          disabled={isAccepting}
        >
          {t('common:reject')}
        </PromiseButton>
        <PromiseButton
          onClick={async () => {
            await onAction(acceptTransfer);
            replace(NavigationRoutes.MySim);
          }}
          fullWidth
          disabled={isRejecting}
        >
          {t('common:accept')}
        </PromiseButton>
      </div>
    </div>
  );
}

interface EsimInstallationCallToActionProps {
  id: string;
  token?: string;
}

export function EsimInstallationCallToAction(
  props: EsimInstallationCallToActionProps,
) {
  const { id, token } = props;
  const { t } = useTranslation();
  const { push } = useKurosimNavigation();

  const onClick = React.useCallback(() => {
    const destination =
      token != null
        ? NavigationRoutes.EsimPublicInstall
        : NavigationRoutes.EsimInstallGuide;
    push(destination, {
      paths: {
        id,
      },
      query: {
        t: token,
      },
    });
  }, [id, push, token]);

  return (
    <div
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${AssetPaths.SimInstall})`,
      }}
      className={classNames(
        structuralStyles.border(),
        structuralStyles.padding({ all: 16 }),
        structuralStyles.fill({ width: true }),
        structuralStyles.flexbox({
          direction: 'column',
          align: 'stretch',
          fill: true,
          gap: 16,
        }),
      )}
    >
      <Text ta="center" textVariant="body2Regular">
        {t('sim:installing_title')}
      </Text>

      <Button className={highlightTargetName} fullWidth onClick={onClick}>
        {t('sim:install_esim')}
      </Button>
    </div>
  );
}

interface EsimPlansCallToActionProps {
  data: SimModel;
}

export function EsimPlansCallToAction(props: EsimPlansCallToActionProps) {
  const { data } = props;
  const { t } = useTranslation();
  const { push } = useKurosimNavigation();
  const onClick = () => {
    push(NavigationRoutes.Recharge, {
      locker: {
        label: data.label,
      },
      paths: {
        id: data.id,
      },
      query: {
        recharge: data.iccid,
      },
    });
  };

  if (data.status === SimStatusEnum.Assigned) {
    return (
      <>
        <EsimInstallationCallToAction id={data.id} />
        <Separator gap={16} />
        <Button onClick={onClick} fullWidth variant={{ variant: 'secondary' }}>
          {t('sim:add_plan')}
        </Button>
      </>
    );
  }
  if (!data.rechargeable) {
    return (
      <div
        className={classNames(
          structuralStyles.padding({ all: 16 }),
          structuralStyles.border(),
        )}
      >
        <Text>{t('error:sim.recharge.uninstalled')}</Text>
      </div>
    );
  }
  return (
    <Button onClick={onClick} fullWidth>
      {t('sim:add_plan')}
    </Button>
  );
}

interface EsimFeedbackCallToActionProps {
  data: SimModel;
}

export function EsimFeedbackCallToAction(props: EsimFeedbackCallToActionProps) {
  const { push } = useKurosimNavigation();

  const feedbackQuery = useGetLatestPendingFeedback();
  const { mutateAsync: skipFeedbacks, isLoading } =
    useSkipAllPendingFeedbacks();
  const pending = feedbackQuery.data?.data;
  if (!pending || feedbackQuery.isFetching) {
    return null;
  }
  const feedbackTarget = pending.simPlan;
  return (
    <>
      <Separator gap={24} />
      <div
        className={classNames(
          EsimCallToActionStyles.container,
          structuralStyles.padding({ all: 16 }),
          structuralStyles.flexbox({ direction: 'column', gap: 8 }),
        )}
        style={{
          position: 'relative',
        }}
        onClick={() => {
          push(NavigationRoutes.LatestFeedbacks, {
            paths: {
              id: feedbackTarget.id,
            },
            query: {
              rating: undefined,
            },
          });
        }}
      >
        <FeedbackBackground
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
          }}
          width="100%"
          preserveAspectRatio="none"
          height="100%"
        />
        <div
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
          }}
        >
          <ActionIcon
            variant="transparent"
            loading={isLoading}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              try {
                const res = await skipFeedbacks();
                if (res.message) {
                  notification.success({ message: res.message });
                }
              } catch (e) {
                if (e.message) {
                  notification.error({ message: e.message });
                }
              }
              queryClient.invalidateQueries([feedbackKey.pendingKey]);
            }}
          >
            <CancelIcon color={colors.foregroundTertiary} size={18} />
          </ActionIcon>
        </div>
        <EsimPlanComponent.Flag
          areaCode={feedbackTarget.areaCode}
          scale={feedbackTarget.scale}
          type={feedbackTarget.type}
        />
        <Text textVariant="body1Semibold">
          <Trans
            i18nKey="sim:rating.cta_prompt"
            values={{
              extra: pending.simPlan.name,
            }}
            components={[
              feedbackTarget.grade === ProductItemGradeEnum.Premium ? (
                <PremiumPlanIcon
                  size={18}
                  // This is required to align the crown. I could've used flex but then that introduces a problem with text wrap.
                  style={{ marginBottom: -4, marginLeft: 2, marginRight: 2 }}
                />
              ) : (
                <></>
              ),
            ]}
          />
        </Text>
        <RatingComponent
          rating={undefined}
          setRating={(rating) => {
            push(NavigationRoutes.LatestFeedbacks, {
              query: {
                rating: rating.toString(),
              },
            });
          }}
        />
      </div>
    </>
  );
}
