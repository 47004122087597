export default function FeedbackBackground(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width="343"
      height="145"
      viewBox="0 0 343 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12521_135633)">
        <g filter="url(#filter0_f_12521_135633)">
          <circle cx="224" cy="-111" r="226.5" stroke="#C7F1FF" />
        </g>
        <g filter="url(#filter1_f_12521_135633)">
          <circle cx="224" cy="-75" r="250.5" stroke="#C7F1FF" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_12521_135633"
          x="-4"
          y="-339"
          width="456"
          height="456"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.5"
            result="effect1_foregroundBlur_12521_135633"
          />
        </filter>
        <filter
          id="filter1_f_12521_135633"
          x="-28"
          y="-327"
          width="504"
          height="504"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.5"
            result="effect1_foregroundBlur_12521_135633"
          />
        </filter>
        <clipPath id="clip0_12521_135633">
          <rect width="343" height="145" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
